/* .Admin {
  text-align: center;
} */

.form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form input{
  width: 300px;
  height: 40px;
  margin: 10px;
}

.form label{
  align-items:flex-start;
  width: 300px;
  margin: 10px;
}

.title{
  color : rgba(131, 75, 152, 1);
  font-weight: bold;
}
.textpurpleLight{
  color : rgb(239, 222, 245);
  margin-bottom: 20px;
}
.divPurple {
  background: rgba(131, 75, 152, 1);
}
.divPurpleLight {
  background: rgb(222, 192, 233);
}

.simulador{
  background-color: rgba(248, 249, 250, 1);
}
.divClasico{
  color: rgb(137, 153, 187);
}
.divPro{  
  color: rgb(216, 15, 156);
}
.divMaster{
  color: rgb(72, 7, 194);
}

.divPorcentaje{
  color: rgb(194, 76, 7);
}
.divRangos{
  color: rgb(8, 158, 20);
}
